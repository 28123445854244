@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-decoration: none solid rgb(15, 15, 15);
}

.wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.btn-box {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .btn-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.success-msg {
  width: 100%;
  padding: 14px;
  background-color: #dff0d8;
  color: #468847;
}

.error-msg {
  width: 100%;
  padding: 14px;
  background-color: #f0d0d3;
  color: #df786f;
}

.link {
  text-decoration: none;
}

/* navbar */
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.navbar .logo {
  width: 150px;
  height: auto;
}

.navbar .logo img {
  width: 100%;
  height: 100%;
}

.navbar .rightside {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-right: 24px;
  align-items: center;
}

@media (max-width: 450px) {
  .navbar .rightside {
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-right: 0px;
  }
}

.navbar .rightside div {
  margin: 0px 10px;
}

@media (max-width: 450px) {
  .navbar .rightside div {
    margin: 10px 0px;
  }
}

.navbar .rightside div .navlink {
  text-decoration: none;
  color: #000;
}

/* products */
.products-box {
  width: 100%;
  height: 450px;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.products-box.cart {
  height: auto;
  overflow-y: auto;
}

@media (max-width: 540px) {
  .products-box {
    height: auto;
  }
}

.product {
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-right: 24px;
  transition: all 0.3s ease-in-out;
  padding: 12px;
  border-radius: 10px;
}

.product:hover {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .product {
    width: 300px;
  }
}

.product .product-img {
  width: 180px;
  height: 215px;
}

.product .product-img img {
  width: 100%;
  height: 100%;
}

.product .product-text {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
}

.product .product-text.title {
  color: #e00a02;
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
}

.product .product-text.description {
  height: 50px;
  overflow: hidden;
}

.product .product-text.price {
  font-weight: 600;
}

.product .product-text.cart-price {
  font-weight: 600;
  color: #e00a02;
}

.cart-btn {
  background-color: #e00a02 !important;
}

.cart-menu-btn {
  position: relative;
}

.cart-menu-btn .cart-indicator {
  width: 18px;
  background-color: #e00a02;
  padding: 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  top: -17px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product .quantity-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding: 5px;
  border: 1px solid #b9b5b5;
  border-radius: 8px;
}

.product .quantity-box .action-btns {
  font-weight: 600;
  cursor: pointer;
}

/* summary box */
.summary-box {
  width: 300px;
  margin: 30px auto;
  border: 1px solid #b9b5b5;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.summary-box h5 {
  display: flex;
  border-bottom: 1px solid #e00a02;
  text-align: center;
  padding: 0px;
}

.summary-box div {
  display: flex;
  justify-content: space-between;
}

/* modal css */
.shade-area {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 300px;
  height: auto;
  padding: 20px;
  background-color: #fff;
  position: relative;
}

.delete-icon {
  background-color: #e00a02;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

/* modal css */
.shade-area {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 300px;
  height: auto;
  padding: 20px;
  background-color: #fff;
  position: relative;
}

.delete-icon {
  background-color: #e00a02;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

/* filter */
.filter-products-main-box {
  width: 100%;
  display: flex;
}

@media (max-width: 540px) {
  .filter-products-main-box {
    flex-direction: column;
  }
}

.filter-products-main-box .my-products {
  flex: 3.3;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 540px) {
  .filter-products-main-box .my-products {
    width: 100%;
    margin-top: 30px;
  }
}

.filter-box {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  flex: 0.7;
  padding-left: 15px;
  padding-top: 10px;
}

@media (max-width: 540px) {
  .filter-box {
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
  }
}

.filter-box h6 {
  font-weight: 600;
  margin-bottom: 20px;
}

.filter-box span {
  cursor: pointer;
  margin: 15px 0px;
  font-size: 14px;
}

.filter-box span.ElectronicDevices {
  color: #e00a02;
}

.filter-box span.MobileAccessories {
  color: #e00a02;
}

.filter-box span.TVAndHomeAppliances {
  color: #e00a02;
}

.filter-box span.SportsAndOutdoors {
  color: #e00a02;
}

.HealthAndBeauty,
.HomeAndLifestyle,
.MensFashion,
.WatchesBagsAndJewellery,
.Groceries {
  color: #e00a02;
}

.rightside {
  width: 440px;
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 80vh;
}
/* cart summary */
.rightside .cart-summary-box {
  width: auto;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 90px;
  z-index: 99;
}

.rightside .cart-summary-box h5 {
  text-align: center;
  padding: 0px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-decoration: none solid rgb(15, 15, 15);
  font-size: 18px;
  margin-top: 20px;
}

.rightside .cart-summary-box .your-basket {
  margin: 0 auto;
  margin-bottom: 10px;
  color: #5d5d5d;
}

.your-basket1 {
  color: #5d5d5d;
  margin-left: 6px;
  margin-bottom: 6px;
}

.rightside .cart-summary-box div {
  display: flex;
  flex-direction: column;
}

.rightside .cart-summary-box table {
  flex: 1;
}
.empty-basket {
  text-align: center;
}
.rightside .cart-summary-box table {
  border-bottom: 1px solid rgb(61, 61, 61);
}
.rightside .cart-summary-box table tbody {
}
.rightside .cart-summary-box table tbody tr td {
  flex-direction: row;
  border-top: 1px solid rgb(221, 221, 221);
}
.rightside .cart-summary-box .total-section {
  margin-top: 10px;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-right: 35px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 400;
}
.rightside .cart-summary-box .total-section div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rightside .cart-summary-box .total-section span {
  line-height: 26px;
}

.rightside .cart-summary-box .total-section .t-total {
  font-weight: 500;
}
.rightside .cart-summary-box .total-section .del-info {
  font-weight: 400;
  font-size: 14px;
  color: #6f6f6f;
  text-align: left;
  text-indent: 12px;
  margin-top: -4px;
}

.method-container {
  width: 500;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.method {
  width: 400px;
  margin-top: 200px;
  margin-bottom: 200px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  border-radius: 30px;
}

@media (max-width: 400px) {
  .method {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.method:hover {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.method-home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.method-home {
  margin: 0px 30px 0px 30px;
}

/* RES CART TABLE */

@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,400italic|Montserrat:400,700);
* {
  box-sizing: border-box;
}
body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  font-family: "Droid Serif", serif;
}
img {
  max-width: 100%;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  *zoom: 1;
}
.wrap {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 20px;
}
.projTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  padding: 1em 0;
  border-bottom: 1px solid #dadada;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.projTitle span {
  font-family: "Droid Serif", serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase;
  color: #777;
}
.heading {
  border-bottom: 1px solid #e80532;
  margin-top: 5px;
}
.heading h1 {
  font-family: "Merriweather", serif;
  font-size: 38px;
  font-weight: 700;
  margin-top: 10px;
  float: left;
}
.heading a.continue:link,
.heading a.continue:visited {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.015em;
  font-size: 0.75em;
  padding: 1em;
  color: #fff;
  background: #82ca9c;
  font-weight: bold;
  border-radius: 50px;
  float: right;
  text-align: right;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.heading a.continue:after {
  content: "\276f";
  padding: 0.5em;
  position: relative;
  right: 0;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.heading a.continue:hover,
.heading a.continue:focus,
.heading a.continue:active {
  background: #f69679;
}
.heading a.continue:hover:after,
.heading a.continue:focus:after,
.heading a.continue:active:after {
  right: -10px;
}
.tableHead {
  display: table;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75em;
}
.tableHead li {
  display: table-cell;
  padding: 1em 0;
  text-align: center;
}
.tableHead li.prodHeader {
  text-align: left;
}
.cart {
  padding: 1em 0;
}
.cart .items {
  display: block;
  border-bottom: 1px solid #ebebeb;
}
.cart .items.even {
  background: #ebebeb;
}
.cart .items .infoWrap {
  display: table;
  width: 100%;
}
.cart .items .cartSection {
  display: table-cell;
  vertical-align: middle;
}
.cart .items .cartSection .itemNumber {
  font-size: 0.75em;
  color: rgb(130, 130, 130);
}
.cart .items .cartSection h3 {
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 18px;
}
.cart .items .cartSection p {
  display: block;
  color: rgb(130, 130, 130);
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.32px;
  font-size: 16px;
}
.cart .items .cartSection p .quantity {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: 0.32px;
  font-size: 18px;
  color: #333;
}
.cart .items .cartSection p.stockStatus {
  color: #82ca9c;
  font-weight: bold;
  padding: 0.5em 0 0 1em;
  text-transform: uppercase;
}
.cart .items .cartSection p.stockStatus.out {
  color: #f69679;
}
.cart .items .cartSection .itemImg {
  width: 4em;
  float: left;
}
.cart .items .cartSection.qtyWrap,
.cart .items .cartSection.prodTotal {
  text-align: center;
}
.cart .items .cartSection input.qty {
  width: 2em;
  text-align: center;
  font-size: 1em;
  padding: 0.25em;
  margin: 1em 0.5em 0 0;
}
.cart .items .cartSection .itemImg {
  width: 8em;
  display: inline;
  padding-right: 1em;
}
.cart .items .right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.cart .items .right-container .prodTotal {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: 0.32px;
  font-size: 18px;
  margin-top: 12px;
}
.cart .items .right-container .deleteProd {
  margin-left: 10px;
  margin-top: 12px;
  cursor: pointer;
}
.special {
  display: block;
  font-family: "Montserrat", sans-serif;
}
.special .specialContent {
  padding: 1em 1em 0;
  display: block;
  margin-top: 0.5em;
  border-top: 1px solid #dadada;
}
.special .specialContent:before {
  content: "\21b3";
  font-size: 1.5em;
  margin-right: 1em;
  color: #6f6f6f;
  font-family: helvetica, arial, sans-serif;
}
a.remove {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: bold;
  background: #e0e0e0;
  padding: 0.5em;
  font-size: 0.75em;
  display: inline-block;
  border-radius: 100%;
  line-height: 0.85;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
a.remove:hover {
  background: #f30;
}
.bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-container .promoCode {
  border: 2px solid #efefef;
  flex: 1;
  height: max-content;
  max-width: 400px;
}
.bottom-container .promoCode label {
  font-family: "Merriweather", serif;
  font-size: 20px;
  font-weight: 700;
}
.bottom-container .promoCode input {
  width: 85%;
  font-size: 1em;
  padding: 0.5em;
  border: 1px solid #dadada;
}
.bottom-container .promoCode input:active,
.bottom-container .promoCode input:focus {
  outline: 0;
}
.bottom-container .promoCode a.btn {
  float: left;
  width: 15%;
  padding: 0.75em 0;
  border-radius: 0 1em 1em 0;
  text-align: center;
  border: 1px solid #82ca9c;
}
.bottom-container .promoCode a.btn:hover {
  border: 1px solid #f69679;
  background: #f69679;
}
.bottom-container .promoCode .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.bottom-container .promoCode .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}
/* TOTAL AND CHECKOUT */
.bottom-container .total-checkout {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 2px solid #efefef;
}
.bottom-container .total-checkout .subtotal {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.bottom-container .total-checkout .subtotal div {
  margin-bottom: 5px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-container .total-checkout .subtotal .label {
  margin-right: 140px;
  margin-left: 20px;
}
.bottom-container .total-checkout .subtotal .value {
  justify-content: flex-end;
}

.bottom-container .total-checkout .arrowbtn .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.bottom-container .total-checkout .arrowbtn .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}

@media only screen and (max-width: 740px) {
  .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -5px;
  }
  .bottom-container .promoCode {
    margin: 0 auto;
    flex: none;
    height: max-content;
    width: 90%;
    margin-bottom: 25px;
  }
  .bottom-container .total-checkout .subtotal .label {
    margin-right: 0px;
  }
}
.arrowbtn .btn:link,
.arrowbtn .btn:visited {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.015em;
  font-size: 1em;
  padding: 1em 3em;
  color: #fff;
  background: #82ca9c;
  font-weight: bold;
  border-radius: 50px;
  float: right;
  text-align: right;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.arrowbtn .btn:after {
  content: "\276f";
  padding: 0.5em;
  position: relative;
  right: 0;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.arrowbtn .btn:hover,
.arrowbtn .btn:focus,
.arrowbtn .btn:active {
  background: #f69679;
}
.arrowbtn .btn:hover:after,
.arrowbtn .btn:focus:after,
.arrowbtn .btn:active:after {
  right: -10px;
}

/* end of checkout */
.cart-indicator1 {
  width: 18px;
  background-color: #e00a02;
  padding: 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* modal popup */

.rightside1 {
  width: auto;
}
/* cart summary */
.rightside1 .cart-summary-box {
  width: auto;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 90px;
  z-index: 99;
}

.rightside1 .cart-summary-box h5 {
  border-bottom: 1px solid #e00a02;
  text-align: center;
  padding: 0px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-decoration: none solid rgb(15, 15, 15);
  font-size: 18px;
}
.rightside1 .cart-summary-box table {
  border-bottom: 1px solid rgb(61, 61, 61);
}
.rightside1 .cart-summary-box table tbody {
}
.rightside1 .cart-summary-box table tbody tr td {
  flex-direction: row;
  border-top: 1px solid rgb(221, 221, 221);
}
.rightside1 .cart-summary-box .total-section {
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 35px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 400;
}
.rightside1 .cart-summary-box .total-section div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rightside1 .cart-summary-box .total-section span {
  line-height: 26px;
}

.rightside1 .cart-summary-box .total-section .t-total {
  font-weight: 500;
}
.rightside1 .cart-summary-box .total-section .del-info {
  font-weight: 400;
  font-size: 14px;
  color: #6f6f6f;
  text-align: left;
  text-indent: 12px;
  margin-top: -4px;
}
.rightside1 .cart-summary-box table tbody .empty-basket {
  text-align: center;
}
/* Dashboard Loading */
.spinner {
  animation: spin infinite 5s linear;
  color: #343a40;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* liveorder */
.liveorder {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1560px) {
  .liveorder {
    grid-template-columns: repeat(2, 0.5fr);
  }
}

@media (max-width: 870px) {
  .liveorder {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* .card-body .btn-liveorder {
  margin: 0 auto;
} */

/* modal-popup */
.modal-content {
  overflow: auto;
  height: auto;
  min-height: 10vh;
  max-height: 90vh;
}

/* eachorder */
.orderdetails {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.orderdetails .leftside-orderdetails {
  text-align: start;
}
.orderdetails .leftside-orderdetails p {
  font-weight: bold;
}

.orderdetails .rightside-orderdetails p {
  text-align: right;
  margin-left: 20px;
  word-break: break-word;
}

/* Porfile */
.container-profile {
}

.container-profile .in-profile {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.container-profile .in-profile .leftside-profile {
  flex: 1;
  margin-right: 10px;
}

.container-profile .in-profile .rightside-profile {
  flex: 1;
}

/* Signup */
.container-signup {
}

.container-signup .in-signup {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.container-signup .in-signup .leftside-signup {
  flex: 1;
  margin-right: 10px;
}

.container-signup .in-signup .rightside-signup {
  flex: 1;
}

/* modal-product */
/* individual products  */
.modal-product .modal-title img {
  width: 500px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}

.modal-product .modal-title .title-product {
  margin: 10px;
  margin-bottom: 0;
  border-bottom: 1px solid rgb(167, 167, 167);
  padding-bottom: 2px;
}

.modal-product .modal-title .title-product p {
  margin-bottom: 2px;
}

.modal-product .modal-title .title-product #span-title {
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 26px;
}

.modal-product .modal-title .title-product #span-price {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 26px;
  color: #e80532;
}

.modal-product .modal-title #p-description {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 14px;
  word-wrap: break-word;
}

.modal-product .modal-body .scrollbar-modal {
  height: auto;
  min-height: 400px;
}

/* option and add-on*/
.option-label-title {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.radio-margin {
  margin: 0;
  /* padding: 0; */
  /* border: 1px solid black; */
}

.option-label-menu {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.modal-product .modal-body .instruction {
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 16;
}

.modal-product .modal-body .instruction .text-instruction {
  font-size: 16px;
}

.modal-product .modal-footer .btn-basket {
  background-color: #e80532;
  border-color: #e80532;
}

@media (max-height: 855px) {
  .modal-product .modal-title img {
    height: 180px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 24px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 24px;
  }

  .modal-product .modal-title #p-description {
    font-size: 14px;
  }

  .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 365px;
  }

  /* option and add-on*/
  .option-label-title {
    font-size: 14px;
  }

  .option-label-menu {
    font-size: 12px;
  }

  .modal-product .modal-body .instruction {
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 14px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 14px;
  }
}

@media (max-height: 825px) {
  .modal-product .modal-title img {
    height: 180px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 22px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 22px;
  }

  .modal-product .modal-title #p-description {
    font-size: 14px;
  }

  .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 335px;
  }

  /* option and add-on*/
  .option-label-title {
    font-size: 14px;
  }

  .modal-product .modal-body .instruction {
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 14px;
  }
}

@media (max-height: 785px) {
  .modal-product .modal-title img {
    height: 180px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 20px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 20px;
  }

  .modal-product .modal-title #p-description {
    font-size: 14px;
  }

  .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 295px;
  }

  /* option and add-on*/
  .option-label-title {
    font-size: 14px;
  }

  .modal-product .modal-body .instruction {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 14px;
  }
}

@media (max-height: 760px) {
  .modal-product .modal-title img {
    height: 160px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 18px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 18px;
  }

  .modal-product .modal-title #p-description {
    font-size: 12px;
  }

  .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 270px;
  }

  /* option and add-on*/
  .option-label-title {
    font-size: 14px;
  }

  .modal-product .modal-body .instruction {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 14px;
  }
}

@media (max-height: 710px) {
  .modal-product .modal-title img {
    height: 140px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 16px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 16px;
  }

  .modal-product .modal-title #p-description {
    font-size: 12px;
  }

  .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 220px;
  }

  /* option and add-on*/
  .option-label-title {
    font-size: 14px;
  }

  .modal-product .modal-body .instruction {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 14px;
  }
}

@media (max-height: 652px) {
  .modal-product .modal-title img {
    height: 120px;
  }

  .modal-product .modal-title .title-product {
    margin: 10px;
    margin-bottom: 0;
  }

  .modal-product .modal-title .title-product #span-title {
    font-size: 16px;
  }

  .modal-product .modal-title .title-product #span-price {
    font-size: 16px;
  }

  .modal-product .modal-title #p-description {
    font-size: 10px;
  }

  /* .modal-product .modal-body .scrollbar-modal {
    height: auto;
    min-height: 100px;
  } */

  /* option and add-on*/
  .option-label-title {
    font-size: 12px;
  }

  .modal-product .modal-body .instruction {
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .modal-product .modal-body .instruction .text-instruction {
    font-size: 12px;
  }
}
