* {
  content: none !important;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url('../../font/Kanit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media print {
  .receipt {
    display: block;
    page-break-before: always;
  }
}

@media all {
  .receipt {
  }
}

@page {
  margin: 0;
}

.receipt {
  font-size: 18px !important;
  /* font-family: 'Times New Roman' !important; */
  font-family: 'Kanit';
  width: 108mm !important;
  padding-top: 4mm;
  padding-bottom: 8mm;
  padding-left: 8mm;
}

p {
  font-family: 'Kanit';
}

.centered {
  font-family: 'Kanit';
  text-align: center !important;
  align-content: center !important;
  margin: 0;
}

.ticket {
}

.receipt-table {
  border-top: 1px solid rgb(85, 85, 85) lack !important;
  border-collapse: collapse !important;
  width: 100%;
}

td.description,
th.description {
  font-family: 'Kanit';
  border-top: 1px solid rgb(85, 85, 85) !important;
  border-collapse: collapse !important;
}

td.quantity,
th.quantity {
  font-family: 'Kanit';
  border-top: 1px solid rgb(85, 85, 85) !important;
  border-collapse: collapse !important;
  width: 50px !important;
  word-break: break-all !important;
  text-align: center;
  vertical-align: top;
}

td.price,
th.price {
  font-family: 'Kanit';
  border-top: 1px solid rgb(85, 85, 85) !important;
  border-collapse: collapse !important;
  width: 100px !important;
  word-break: break-all !important;
  text-align: center;
  vertical-align: top;
}

/* @media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
} */
