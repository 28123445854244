@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

/* NAVBAR */
.navbar-font {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.navbar-font-1 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  bottom: 10px;
}
.navbar-logo-font {
  font-family: "Merriweather", serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.login-logo-font {
  font-family: "Merriweather", serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 28px;
  margin-left: 10px;
}
.navbar-logo-font1 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.navbar-font p {
  color: black;
  font-size: 16px;
}
.navbar-font-ham {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  text-decoration: none solid rgb(15, 15, 15);
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-size: 16px;
  vertical-align: center;
  display: flex;
  flex-direction: row;
}
.navbar-font-ham span {
  border: 2px solid white;
  color: black;
  margin-left: 10px;
}
.navbar-icon {
  color: #5d5d5d;
}
/* HOME */
.placeholder-category-tab {
  height: 20px;
  margin-top: 7px;
  width: 800px;
}
.placeholder-category-tab span {
}
/* HOME: CATEGORY NEW SCROLLBAR */
.category-container-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.category-container-c .category-tab-c {
  display: flex;
  flex-direction: row;
  justify-content: left;
  white-space: nowrap;
  margin-top: 18px;
}
.category-container-c .category-tab-c .category-ind-c {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  width: max-content;
  height: min-content;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.category-container-c .category-tab-c .category-ind-c:hover {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}
.category-container-c .category-tab-c span {
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
}
.scrollbar-new {
  height: 100;
  width: 500;
}

/* HOME */
.all-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.all-home .menu {
  flex: 1;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.center-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.center-img img {
  max-width: 100%;
  max-height: 100%;
}
.all-home .menu h1 {
  font-family: "Merriweather", serif;
  font-size: 38px;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}
.all-home .menu .a-container {
  text-align: center;
  margin-top: -10px;
}
.all-home .menu .a-container a {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.32px;
  color: #e80532;
}
.all-home .menu .menu-container {
  display: flex;
  flex-wrap: wrap;
}

/* HOME: MENU (INDIVIDUAL PRODUCT) */
.ind-menu {
  margin: 10px;
  float: left;
  width: 47%;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
@media (max-width: 1130px) {
  .ind-menu {
    width: 100%;
    float: none;
  }
}
.ind-menu:hover {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
}
.ind-menu .menu-img {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  margin-left: 15px;
  margin-right: 15px;
}
.ind-menu .menu-img img {
  max-width: inherit;
  width: inherit;
  object-fit: cover;
}
.ind-menu .menu-text .title {
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}
.ind-menu .menu-text .desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  line-height: 20px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-family: "Rubik", serif;
  font-size: 15px;
  color: rgb(130, 130, 130);
  margin-top: 2px;
  margin-bottom: 2px;
}
.ind-menu .menu-text .price {
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.32px;
  font-size: 18px;
  font-weight: 500;
  color: #e80532;
}
.ind-menu .btn-add {
  height: min-content;
  margin-left: auto;
  cursor: pointer;
}
.ind-menu .btn-add .addicon {
  margin-right: 15px;
  margin-left: 5px;
}
@media (max-width: 410px) {
  .center-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ind-menu {
    float: none;
    margin: 0 auto;
  }
  .ind-menu .menu-text .title {
    font-family: "Merriweather", serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  .ind-menu .menu-img {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .category-container-c {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .category-container-c .category-tab-c {
    display: flex;
    flex-direction: row;
    justify-content: left;
    white-space: nowrap;
    margin-top: 20px;
  }
  .category-container-c .category-tab-c .category-ind-c {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    width: max-content;
    height: min-content;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .category-container-c .category-tab-c .category-ind-c:hover {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  }
  .category-container-c .category-tab-c span {
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    word-wrap: break-word;
  }
}
@media (max-width: 340px) {
  .center-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ind-menu {
    float: none;
    margin: 0 auto;
    height: 100px;
  }
  .ind-menu .menu-text .title {
    font-family: "Merriweather", serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
  .ind-menu .menu-img {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
  }
}

/* HOME: MEDIA YOUR BASKET FLOAT BTN) */
.yourbasket-floatbtn {
  border-radius: 50%;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
}
.yourbasket-floatbtn .basket-icon {
  color: #e80532;
  margin: 3px 3px 13px 3px;
}

/* EVERY BTN HAS POINTER */
.action-btns-pointer {
  cursor: pointer;
}

/* ORDER: IF BASKET EMPTY SHOW PLAIN TEXT */
.basket-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}
.basket-empty h1 {
  font-size: 26px;
}
.basket-empty .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.basket-empty .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}

/* CHECKOUT */
.checkout-container {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 35px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* CHECKOUT: FORM (LEFT SIDE) */
.checkout-container .checkout-form {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  flex: 1;
  margin-right: 30px;
}
.checkout-container .checkout-form h1 {
  font-family: "Merriweather", serif;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
.checkout-container .checkout-form .inst-form {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
}

/* CHECKOUT: SUMMARY TABLE (RIGHT BOX) */
.checkout-container .checkout-table {
  width: 400px;
  height: max-content;
  border: 2px solid #efefef;
}
.checkout-container .checkout-table .c-cart-summary-box {
  width: auto;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 90px;
  z-index: 99;
  margin-left: 15px;
  margin-right: 15px;
}
.checkout-container .checkout-table .c-cart-summary-box h5 {
  border-bottom: 1px solid #e00a02;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-decoration: none solid rgb(15, 15, 15);
  font-size: 18px;
}
.checkout-container .checkout-table .c-cart-summary-box table tbody tr {
  border-bottom: 1px solid rgb(203, 203, 203);
}
.checkout-container
  .checkout-table
  .c-cart-summary-box
  table
  tbody
  tr
  .c-price {
  text-align: right;
  width: 90px;
}
.checkout-container .checkout-table .c-cart-summary-box .c-payment {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 6px;
}
.checkout-container .checkout-table .c-cart-summary-box .c-payment .c-paypal {
  margin-bottom: 10px;
}
/* CHECKOUT: RIGHT BOX TO BOTTOM */
@media (max-width: 900px) {
  .checkout-container {
    flex-direction: column;
  }
  .checkout-container .checkout-form {
    margin-right: 0px;
  }
  .checkout-container .checkout-table {
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .checkout-container .checkout-table {
    width: auto;
    margin: 0 auto;
  }
}

.ordersuccess-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.ordersuccess-container h1 {
  text-align: center;
  font-size: 26px;
  margin-left: 20px;
  margin-right: 20px;
}
.ordersuccess-container .order-box {
  display: flex;
  flex-direction: column;
  border: 2px solid #efefef;
  text-align: center;
  width: max-content;
  margin: 0 auto;
  margin-top: 10px;
}
.ordersuccess-container .order-box span {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ordersuccess-container .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.ordersuccess-container .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}

.container-profile .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.container-profile .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}

/* .queryProfilebtn {
  display: inherit;
} */

.queryProfilebtn .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.queryProfilebtn .btn:hover {
  background-color: #d4062f;
  border-color: #d4062f;
}
.queryProfilebtn .btn:hover,
.queryProfilebtn .btn:active,
.queryProfilebtn .btn:focus,
.queryProfilebtn .btn:active:focus {
  background-color: #d4062f;
  border-color: #d4062f;
}

.method-btn .btn {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.32px;
  background-color: #e80532;
  border-color: #e80532;
  width: max-content;
}
.method-btn .btn:hover,
.method-btn .btn:active,
.method-btn .btn:focus,
.method-btn .btn:active:focus {
  background-color: #d4062f;
  border-color: #d4062f;
}
.display-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.placeholder-navbar {
  width: 220px;
  height: 34px;
  margin-right: 20px;
}
.nav-link,
.brand-link {
  cursor: pointer;
}

.text-hint {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #808080;
}
